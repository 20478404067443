.card-mtbf {
	.card-text {
		font-size: 0.8rem;
	}
}
.title {
	font-weight: 400 !important;
	font-size: 12px;
	color: #868686;
}
.table-mtbf {
	th {
		font-weight: 400 !important;
		padding-bottom: 2rem !important;
		font-size: 12px;
		color: #868686;
	}
	td {
		padding-bottom: 0.5rem !important;
		padding-top: 0.5rem !important;
	}
	background-color: transparent !important;
	th:first-child {
		padding-left: 0 !important;
	}
	td:first-child {
		padding-left: 0 !important;
	}
}