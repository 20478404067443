body {
  min-height: unset !important;
  font-weight: 300 !important;
}

.h-90 {
  height: 90%;
}

.modal {
  z-index: 1061 !important;
}

.no-mastnav {
  .actionbar {
    width: 100%;
  }
  .mastcontainer {
    padding-left: 0;
  }
}

.loader {
  text-align: center;
}

.ml-10 {
  margin-left: 40vw;
}

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .mastheader-logo {
    flex: 0 0 content !important;
    min-width: 0;
  }
}
.btn:focus {
  outline: none;
}

.loader,
.login-form {
  margin-top: 50vh;
  transform: translateY(-50%);

  &.center {
    margin: inherit;
    transform: inherit;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.login-form .logo {
  max-height: 18rem;
}

.version {
  position: absolute;
  right: 0;
  font-size: 0.7rem;
}

/* Make cool transitions for fullscreen mode */
.mastheader,
.mastnav,
.mastcontainer,
.actionbar,
.table-filters,
.sideBar-container,
.widget {
  transition: 0.4s ease-in-out;
}

.mastnav.fullscreen {
  left: -7rem !important;
}

.mastheader.fullscreen {
  top: -5rem !important;
}

.actionbar.fullscreen {
  top: 0 !important;
  width: 100% !important;
}

.mastcontainer.fullscreen {
  padding-top: 4.5rem !important;
  padding-left: 0 !important;
}

.table-filters.fullscreen {
  top: 5rem !important;
}

.sideBar-container.fullscreen {
  left: -6.5rem !important;
  top: -3.7rem !important;
  height: 109vh !important;
}

/* fullscreen switch */

.btn-fullscreen {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  font-size: 2rem !important;
}
.btn-resetviewport {
  position: absolute;
  bottom: 5rem;
  right: 0.5rem;
  font-size: 2rem !important;
}
.btn-sm {
  font-size: small !important;
}

/* media queries won't accept CSS variables... */

/* lg breakpoint 1024 px */
@media screen and (max-width: 1023px) {
  .btn-fullscreen {
    bottom: 6rem;
  }
  .btn-resetviewport {
    bottom: 9rem;
  }
}

@media screen and (max-width: 1023.98px) {
  .sideBar-container {
    padding-left: 0;
    padding-top: 0;
    margin-top: 8rem;
    bottom: 0;
    max-width: 100vw;
    width: 100vw;
    top: 30%;
  }
  .sideBar-content {
    max-width: 100vw;
  }
  .sideBar-container.fullscreen {
    left: 0 !important;
    top: 30% !important;
  }
}

@media screen and (min-width: 1280px) {
  .sideBar-container {
    padding-top: 9rem;
  }
  .sideBar-container.fullscreen {
    top: -4.15rem;
  }
}

.tab-content {

}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.creation-sidebar.fullscreen {
  left: -6.25rem !important;
  top: -4.5rem !important;
  transition-delay: 0.2s;
}

@media screen and (max-width: 1023.98px) {
  .creation-sidebar {
    padding-left: 0.5rem !important;
    padding-top: 17.8rem !important;
    transition-delay: 0.11s;
  }
  .creation-sidebar.fullscreen {
    transition-delay: 0.25s;
    top: -4.25rem !important;
    left: 0 !important;
  }
}

.sentry-error-embed-wrapper {
  padding-top: 7.5% !important;
  z-index: 1057 !important;
}

.navtabs-item a {
  padding-bottom: 0.25rem !important;
}

.form-control-sm {
  padding: 0.125rem 0.8rem !important;
  min-height: 1.475rem !important;
  font-size: small !important;
}

.sentry-error-embed {
  max-height: 100% !important;
}

.cell-fixed {
  width: 2rem !important;
  min-height: 0 !important;
}

.table-scroller {
  overflow-x: scroll !important;
}

.btn-only-icon {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.alert-loading {
  position: fixed !important;
  top: 0;
  z-index: 3520;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.25rem 0.5rem !important;
  border-radius: 0 0 0.4375rem 0.4375rem !important;
}

.sidebar {
  top: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 17rem !important;
  height: 100%;
  background-color: #f2faff;
}

.zonescontainer {
  padding-top: 4.25rem;
  margin: 3rem;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  color: #747678;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-tabs .nav-link {
  background: 0 0;
}

.nav-item a {
  padding-bottom: 0.25rem !important;
}

.nav-item a.active {
  font-weight: 400;
  color: #0088ce !important;
}

.nav-item a.active:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.125rem;
  content: "";
  background-color: #0088ce;
  border-radius: 3px;
}

.nav-item a.active,
.nav-item a:active,
.nav-item a:focus,
.nav-item a:hover {
  position: relative;
  color: #0088ce;
}

.card-button {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  border-color: #fff;
  color: #747678;
  background-color: #fff;
  border-color: #fff;
}

.card-button:hover {
  color: #0088ce !important;
}

.card-button:hover .dashboard-icon {
  filter: invert(39%) sepia(97%) saturate(4613%) hue-rotate(182deg)
    brightness(90%) contrast(101%);
}

.active-card {
  .card-button {
    color: #0088ce !important;
  }
  .dashboard-icon {
    filter: invert(39%) sepia(97%) saturate(4613%) hue-rotate(182deg)
      brightness(90%) contrast(101%);
  }
}

.dropdown button {
  padding: 0rem;
  color: black;
}

.dashboard-icon {
  filter: invert(44%) sepia(5%) saturate(187%) hue-rotate(169deg)
    brightness(101%) contrast(81%);
}

.zone {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.hvr-fade {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.hvr-fade:hover,
.hvr-fade:focus,
.hvr-fade:active {
  background-color: #2098d1;
  color: white;
}

.disabled-zone {
  cursor: not-allowed;
  opacity: 0.5;
}

.hover-effect:hover {
  box-shadow: 0 3px 5px rgba(148, 148, 148, 0.2);
  opacity: 0.4;
}
.zone-display {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .dropdown-menu[data-popper-placement="bottom-start"] {
  transform: translate3d(-57px, 10px, 0px) !important;
  height: 1.7rem;
  min-width: 0;
  width: 5rem;
  padding: 0;
}

.dropdown-menu[data-popper-placement="bottom-end"] {
  transform: translate3d(0px, 10px, 0px) !important;
  height: 1.7rem;
  min-width: 0;
  width: 5rem;
  padding: 0;
}

.dropdown-menu[data-popper-placement="bottom-start"]::before,
.dropdown-menu[data-popper-placement="bottom-start"]::after,
.dropdown-menu[data-popper-placement="bottom-end"]::before,
.dropdown-menu[data-popper-placement="bottom-end"]::after {
  left: 4.15rem !important;
} */
.card-body svg {
  width: 100%;
}

.dashboard {
  height: calc(100vh - 3.75rem);
}

@media (min-width: 1280px) {
  .dashboard {
    height: calc(100vh - 4.0625rem);
  }
}

.select-form {
  height: 2.4rem;
  font-size: inherit !important;
  font-weight: 400 !important;
  background-color: #ffffff !important;
}

.multi-select-button {
  border-radius: 0 0.4375rem 0.4375rem 0 !important;
}

.input-form {
  min-height: 2.4rem !important;
  height: 2.4rem !important;
  font-size: 0.95rem !important;
  background-image: none !important;
}

.input-form::placeholder {
  color: #0088ce !important;
}

.input-form-search {
  min-height: 2rem !important;
  height: 2rem !important;
}

.representation-button {
  color: #fff !important;
  background-color: #0088ce !important;
  border-color: #0088ce !important;
}
.form-row .form-control:focus {
  border: none !important;
}

.calendar {
  border-radius: 0 0.4375rem 0.4375rem 0 !important;
  background-color: #0088ce !important;
  color: #fff !important;
}

.daterangepicker select {
  border-bottom-color: #f2f2f2 !important;
}

.daterangepicker select:focus {
  border-bottom-color: #d7d7d7 !important;
}

.daterangepicker select.monthselect,
select.yearselect {
  min-height: 1.8125rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23000;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-down%3C/title%3E%3Cpath class='a' d='M25,40.42,1.15,17a4.69,4.69,0,0,1,0-6.15A5.15,5.15,0,0,1,4.23,9.65a4.38,4.38,0,0,1,3.08,1.16L25,28.12,42.69,10.81a4.6,4.6,0,0,1,6.93.77,5.39,5.39,0,0,1-1.16,5.77Z'/%3E%3C/svg%3E"),
    linear-gradient(to left, #fff 1.125rem, #fff 1.125rem);
  background-position: center right 0.3875rem, top left;
  background-size: 0.425rem, 100%;
  padding-left: 0.5rem !important;
  z-index: 1051;
}

.daterangepicker select.monthselect:focus,
select.yearselect:focus {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23000;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-up%3C/title%3E%3Cpath class='a' d='M25,9.58,48.85,33a4.69,4.69,0,0,1,0,6.15,5.15,5.15,0,0,1-3.08,1.16,4.38,4.38,0,0,1-3.08-1.16L25,21.88,7.31,39.19a4.6,4.6,0,0,1-6.93-.77,5.39,5.39,0,0,1,1.16-5.77Z'/%3E%3C/svg%3E"),
    linear-gradient(to left, #fff 1.125rem, #fff 1.125rem);
}

.daterangepicker.opensright::before {
  left: 28rem !important;
}

.daterangepicker.opensright:after {
  left: 28.09rem !important;
}

.daterangepicker.opensright {
  right: 10px !important;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 5px !important;
  font-weight: unset !important;
  padding: 0px 2px !important;
}

.daterangepicker .drp-buttons .btn-sm {
  font-size: x-small !important;
}

.widget-resize {
  position: absolute;
  bottom: 1px;
  right: 4px;
  cursor: se-resize;
  transform: rotate(315deg);
}

.widget-card {
  border-color: black !important;
  border-width: thin !important;
  border-style: double !important;
  height: 100%;
  margin-bottom: 0.5rem;
}

.carousel-indicators {
  margin: 0px;
}

.carousel-indicators li {
  background-color: #333 !important;
  width: 15px !important;
  z-index: 10;
}

.carousel-inner {
  height: 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-prev {
  width: 0.75rem !important;
  margin-left: 0.5rem;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next {
  width: 0.75rem !important;
  margin-right: 0.5rem;
}

.carousel-caption {
  height: 100%;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  right: 4% !important;
  bottom: 10px !important;
  left: 4% !important;
}

.graphs-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  width: 100%;
  height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 3px 3px rgb(0 0 0 / 10%);
}

.btn-save-dashboard {
  position: fixed;
  bottom: 3rem;
  right: 20%;
  border-radius: 1.4375rem !important;
  z-index: 4000;
}

.btn-add-widget {
  color: #0088ce !important;
  position: fixed;
  bottom: 3rem;
  left: 20%;
  border-radius: 1.4375rem !important;
  z-index: 6000;
  border: 1px solid #0088ce !important;
}

.btn-lock-position {
  position: fixed;
  color: #0088ce !important;
  border-radius: 1.4375rem !important;
  z-index: 6000;
  border: 1px solid #0088ce !important;
  bottom: 3rem;
  right: 3rem;
}

.action-cursor-pointer:hover {
  cursor: pointer;
}

.widget-container {
  border: #0088ce solid 1px;
}

.navigation-tab {
  background-color: #43454a;
  height: 44px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 4;
  .nav-link {
    padding: 0 1rem;
    color: white;
    font-size: 0.8rem;
    height: 100%;
    min-width: 44px;
    display: flex;
    align-items: center;
  }
  .active {
    background-color: #606164;
  }
  .nav-link:hover {
    background-color: #606164;
  }
}

.icon-delete {
  font-size: 1.3rem;
  display: flex;
}

.icon-delete-default {
  position: absolute;
  right: 0.5rem;
  font-size: 1.3rem;
  top: 0.25rem
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.form-error {
  position: absolute;
  z-index: 2000;
  right: 0;
}

.spinner-border {
  left: calc(50% - 2rem);
  top: calc(50% - 2rem);
  position: absolute;
}

.loader-container {
  background: rgb(255 255 255 / 80%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 6000;
}

.dashboard-action-button {
  display: block !important;
  text-align: center;
  position: fixed !important;
    height: 45px;
    padding: 5px;
    width: 100%;
    z-index: 4;
}

.form-check-multi-enum {
  background: #f2f2f2;
  padding-left: 2.25rem !important;
}

.form-group > .form-check-multi-enum:first-of-type {
  border-top-left-radius: 0.4375rem;
  padding-top: 0.5rem;
  border-top-right-radius: 0.4375rem;
}

.form-group > .form-check-multi-enum:last-of-type {
  border-bottom-left-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
  padding-bottom: 0.5rem;
}

.testlabel {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: #004d99;
  background-color: white;
  padding: 0.25rem;
  border: 1px solid #004d99;
}

.control-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
  label {
    display: flex;
  }
}